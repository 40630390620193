import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Template as MainContainer } from '../components/mainContainer'
import { Seo } from '../components/seo'
import { BoringText, PrettyText } from '../components'
import styled from 'styled-components'
import { updateReviewDom } from '../utils'

const AboutContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${ props => props.theme.media.desktop`
    flex-direction: column;
  ` }
`

const AboutText = styled.div`
  width: 50%;

  ${ props => props.theme.media.desktop`
    width: 100%;
  ` }
`

const ImageContainer = styled.div`
  width: 50%;
  text-align: center;

  ${ props => props.theme.media.desktop`
    width: 100%;
  ` }
`

const AboutImage = styled.img`
  width: 400px;
  height: auto;
  padding: 0 20px;
  // clip-path: circle(200px at center);
  margin: 0 auto;

  ${ props => props.theme.media.desktop`
    width: 300px;
    height: auto;
    padding: 0 20px;
    // clip-path: circle(150px at center);
  ` }
`

class PageTemplate extends React.Component {
  componentDidMount () {
    window.setTimeout(() => {
      updateReviewDom()
    }, 500)
  }

  render () {
    const page = get(this.props, 'data.contentfulPage')
    const title = `${ page.title }`
    return (
      <MainContainer>
        <Seo title={title} location={this.props.location} />
        <AboutContainer>
          { page.aboutImage && (
            <ImageContainer>
              <AboutImage src={page.aboutImage.fixed.src} />
            </ImageContainer>
          )}
          <AboutText>
            <PrettyText>
              { page.salutation || 'hello' }
            </PrettyText>
            { page.content && (
              <BoringText id="review" dangerouslySetInnerHTML={{
                __html: page.content.childMarkdownRemark.html,
              }}/>
            )}
          </AboutText>
        </AboutContainer>
      </MainContainer>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      salutation
      aboutImage {
        fixed(width:600) {
          src
          height
          width
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
